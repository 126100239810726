import { animated, useTransition } from "@react-spring/native";
import React, { useCallback, useState } from "react";
import { View } from "react-native";
import { style } from "./Stack.style";
const Stack = ({ children, style: customStyle }) => {
    const [heights, setHeights] = useState({});
    const handleOnLayout = useCallback((itemKey) => ({ nativeEvent: { layout } }) => setHeights((heights) => ({ ...heights, [itemKey]: layout.height })), []);
    const inState = useCallback((height) => ({ opacity: 1, height, translateY: 0 }), []);
    const transitions = useTransition(children, {
        keys: (item) => `${item.key}`,
        native: true,
        from: { opacity: 0, height: undefined, translateY: -50 },
        enter: (item) => inState(heights[item.key]),
        update: (item) => inState(heights[item.key]),
        leave: { opacity: 0, height: 0, translateY: -50 },
    });
    return (React.createElement(View, { pointerEvents: "box-none", style: [style.stack, customStyle] }, transitions(({ opacity, height, translateY }, item, { phase }) => (React.createElement(animated.View, { key: item.key, testID: "stack-item", style: [
            {
                zIndex: phase === "leave" ? 0 : 10,
                opacity,
                height,
                transform: [{ translateY }],
            },
            style.stackItem,
        ] },
        React.createElement(View, { onLayout: handleOnLayout(item.key) }, item))))));
};
export { Stack };
