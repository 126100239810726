import { Portal } from "@gorhom/portal";
import { animated, useTransition } from "@react-spring/native";
import React, { useCallback, useMemo, useState } from "react";
import { Dimensions, Platform, TouchableWithoutFeedback, View, } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { SafeAreaView, useSafeAreaFrame, useSafeAreaInsets } from "react-native-safe-area-context";
import { useBack } from "@lookiero/aurora";
import { useScreenSize } from "../../../hooks/useScreenSize";
import { theme } from "../../../theme/theme";
import { ButtonIcon } from "../../molecules/buttonIcon/ButtonIcon";
import { Row } from "../row/Row";
import { Sticky } from "../sticky/Sticky";
import { style } from "./Modal.style";
const { space8, space10 } = theme();
const TRANSITION_MODAL_SCALE = 0.9;
const MODAL_DESKTOP_VERTICAL_PADDING = space10;
const Modal = ({ children, visible, header, footer, portalHostName, onClose, showCloseButton = false, size = { M: "2/3", L: "1/3" }, style: customStyle, scroll = false, testID = "modal", scrollRef, }) => {
    const screenSize = useScreenSize();
    const isSmallDevice = screenSize === "S";
    const columnSizeForScreenSize = size[screenSize];
    const { height: windowHeight } = Dimensions.get("window");
    const { height: frameHeight } = useSafeAreaFrame();
    const { top: safeAreaInsetTop, bottom: safeAreaInsetBottom } = useSafeAreaInsets();
    const height = Platform.OS === "ios"
        ? frameHeight - safeAreaInsetTop - safeAreaInsetBottom
        : Platform.OS === "android"
            ? frameHeight
            : windowHeight;
    const modalMaxHeight = height - (!isSmallDevice ? MODAL_DESKTOP_VERTICAL_PADDING : 0);
    const translationY = Platform.OS === "android" ? safeAreaInsetTop : 0;
    const handleHardwareBackPress = useCallback(() => {
        if (!visible) {
            return;
        }
        onClose();
        return true; // The event will not be bubbled up
    }, [onClose, visible]);
    useBack(handleHardwareBackPress);
    const [modalHeight, setModalHeight] = useState();
    const handleOnModalLayout = useCallback(({ nativeEvent: { layout: { height }, }, }) => setModalHeight(height), []);
    const [footerHeight, setFooterHeight] = useState(0);
    const handleOnFooterLayout = useCallback(({ height }) => setFooterHeight(height), []);
    const transitions = useTransition(visible, {
        from: isSmallDevice
            ? {
                modalOpacity: 1,
                overlayOpacity: 0,
                modalTranslateY: (modalHeight || height) + safeAreaInsetBottom,
                modalScale: 1,
            }
            : { modalOpacity: 0, overlayOpacity: 0, modalTranslateY: 0, modalScale: TRANSITION_MODAL_SCALE },
        enter: { modalOpacity: 1, overlayOpacity: 1, modalTranslateY: translationY, modalScale: 1 },
        leave: isSmallDevice
            ? {
                modalOpacity: 1,
                overlayOpacity: 0,
                modalTranslateY: (modalHeight || height) + safeAreaInsetBottom,
                modalScale: 1,
            }
            : { modalOpacity: 0, overlayOpacity: 0, modalTranslateY: 0, modalScale: TRANSITION_MODAL_SCALE },
        unique: true,
    });
    const ModalContentView = useMemo(() => (scroll ? KeyboardAwareScrollView : View), [scroll]);
    return (React.createElement(Portal, { hostName: portalHostName }, transitions(({ modalOpacity, overlayOpacity, modalTranslateY, modalScale }, item) => item && (React.createElement(View, { accessibilityState: { expanded: true }, pointerEvents: visible ? "auto" : "none", style: style.container, testID: testID },
        React.createElement(TouchableWithoutFeedback, { testID: "modal-close-button", onPress: visible ? onClose : undefined },
            React.createElement(animated.View, { style: [
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    style.overlay,
                    { opacity: overlayOpacity },
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    customStyle === null || customStyle === void 0 ? void 0 : customStyle.overlay,
                ] })),
        React.createElement(SafeAreaView, { edges: ["right", "top", "left"], pointerEvents: "box-none", style: style.safeArea },
            React.createElement(Row, { pointerEvents: "box-none", style: [style.row, isSmallDevice && style.rowSmall, customStyle === null || customStyle === void 0 ? void 0 : customStyle.row] },
                React.createElement(animated.View, { pointerEvents: visible ? "auto" : "none", style: [
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        style.modal,
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        isSmallDevice && style.modalSmall,
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        columnSizeForScreenSize && style[columnSizeForScreenSize],
                        {
                            opacity: modalOpacity,
                            transform: [{ translateY: modalTranslateY }, { scale: modalScale }],
                            maxHeight: modalMaxHeight,
                        },
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        customStyle === null || customStyle === void 0 ? void 0 : customStyle.modal,
                    ], onLayout: handleOnModalLayout },
                    (header || showCloseButton) && (React.createElement(View, { style: [style.header, customStyle === null || customStyle === void 0 ? void 0 : customStyle.header] },
                        React.createElement(View, null, header),
                        showCloseButton && (React.createElement(ButtonIcon, { name: "close", style: { button: [style.closeButton, customStyle === null || customStyle === void 0 ? void 0 : customStyle.closeButton] }, onPress: onClose })))),
                    React.createElement(ModalContentView, { ref: scrollRef, contentInset: { top: 0, bottom: 0 }, keyboardShouldPersistTaps: "handled", showsVerticalScrollIndicator: false },
                        React.createElement(View, { style: {
                                paddingBottom: translationY + (safeAreaInsetBottom || space8) + (Platform.OS !== "web" ? footerHeight : 0),
                            } }, children)),
                    footer && (React.createElement(Sticky, { style: style.stickyFooter, onLayout: handleOnFooterLayout }, footer))))))))));
};
export { Modal };
